import React from 'react'

import {
  Badge,
  BulletFillIcon,
  Button,
  GridList,
  Heading,
  InlineList,
  InlineListItem,
  Link,
  List,
  ListItem,
  Message,
  MessageFooter,
  MessageHeader,
  Paragraph,
  SmallText,
  Text
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import lorem from '../../lorem'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Message"
    components={[
      { component: Message, restElement: true },
      { component: MessageHeader },
      { component: MessageFooter }
    ]}
    status={[{ type: 'accessible', version: '13.10.0' }]}
  >
    <Section title="Saavutettavuus">
      <Paragraph>
        <Code>Message</Code>-laatikolle voidaan määritellä otsikko{' '}
        <Code>Heading</Code>-elementillä:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Otsikon semanttinen taso (level) määrittyy <Code>Message</Code>
          -laatikon suhteesta näkymän muihin otsikoihin.
          <List variant="unordered">
            <ListItem>
              <Code>Message</Code>-laatikkoa seuraavien osioiden
              heading-elementit eivät voi olla semanttisesti alisteisia{' '}
              <Code>Message</Code>lle, vaan niiden on oltava semanttisesti
              vähintään saman tasoisia.
            </ListItem>
            <ListItem>
              Esimerkki 1: Jos <Code>Message</Code>-laatikko on semanttisesti
              sivun suoraan sivun pääotsikon alla (level = 1), messagen
              otsikolle määritellään level = 2.
            </ListItem>
            <ListItem>
              Esimerkki 2: Jos <Code>Message</Code>n otsikon level = 2,{' '}
              <Code>Message</Code>a seuraavan osion otsikko ei voi semanttisesti
              level = 3, vaan sen on oltava vähintään level = 2.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>Otsikon visuaaliseksi kooksi määritellään size = 4</ListItem>
      </List>
    </Section>
    <Section title="Viestilaatikko">
      <Paragraph>
        Käytetään linkitetyn otsikon tai painikkeen kanssa, mm.
        laatikkolistauksissa.
      </Paragraph>
      <Playground>
        <GridList
          sm={3}
          noMargin
        >
          {[...Array(3).keys()].map(i => (
            <Message
              noMargin
              key={i}
            >
              <MessageHeader>
                <Link as={LinkMock}>
                  <Heading
                    level={2}
                    noMargin
                    size={4}
                  >
                    {lorem({ words: 3 })}
                  </Heading>
                </Link>
                <SmallText>
                  <Text color="dark">TE-toimisto</Text>
                </SmallText>
              </MessageHeader>
              <Paragraph>{lorem({ sentences: 1 })}</Paragraph>
              <MessageFooter>
                <Text
                  color="dark"
                  size="sm"
                >
                  <InlineList>
                    <InlineListItem>Julkinen palvelu</InlineListItem>
                    <InlineListItem>Maksuton</InlineListItem>
                  </InlineList>
                </Text>
              </MessageFooter>
            </Message>
          ))}
        </GridList>
      </Playground>
      <Playground format="html">
        <ul className="grid-list mb-0 grid-list-sm-3">
          {[...Array(3).keys()].map(i => (
            <li
              className="grid-list-item"
              key={i}
            >
              <article className="message mb-0">
                <header className="message-header">
                  <div className="message-header-content">
                    <LinkMock>
                      <h2 className="h4 mb-0">{lorem({ words: 3 })}</h2>
                    </LinkMock>
                    <p className="small text-dark">TE-toimisto</p>
                  </div>
                </header>
                <p>{lorem({ sentences: 1 })}</p>
                <footer className="message-footer">
                  <div className="text-sm text-dark">
                    <ul className="inline-list">
                      <li className="inline-list-item">Julkinen palvelu</li>
                      <li className="inline-list-item">Maksuton</li>
                    </ul>
                  </div>
                </footer>
              </article>
            </li>
          ))}
        </ul>
      </Playground>
    </Section>
    <Section title="Huomiolaatikko">
      <Playground>
        <Message
          attentionColor="secondary"
          noMargin
        >
          <MessageHeader description={lorem({ words: 2 })}>
            <Heading
              level={2}
              size={4}
            >
              {lorem({ words: 5 })}
            </Heading>
          </MessageHeader>
          <Paragraph noMargin>{lorem({ sentences: 5 })}</Paragraph>
        </Message>
      </Playground>
      <Playground>
        <Message
          attentionColor="secondary"
          color="light"
          noMargin
        >
          <MessageHeader description={lorem({ words: 2 })}>
            <Heading
              level={2}
              size={4}
            >
              {lorem({ words: 5 })}
            </Heading>
          </MessageHeader>
          <Paragraph>{lorem({ sentences: 5 })}</Paragraph>
          <Button as={LinkMock}>{lorem({ words: 2 })}</Button>
        </Message>
      </Playground>
      <Playground format="html">
        <article className="message message-attention-secondary mb-0">
          <header className="message-header">
            <div className="message-header-content">
              <h2 className="h4">{lorem({ words: 5 })}</h2>
            </div>
            <div className="message-header-description">
              {lorem({ words: 2 })}
            </div>
          </header>
          <p className="mb-0">{lorem({ sentences: 5 })}</p>
        </article>
      </Playground>
      <Playground format="html">
        <article className="message message-attention-secondary bg-light mb-0">
          <header className="message-header">
            <div className="message-header-content">
              <h2 className="h4">{lorem({ words: 5 })}</h2>
            </div>
            <div className="message-header-description">
              {lorem({ words: 2 })}
            </div>
          </header>
          <p>{lorem({ sentences: 5 })}</p>
          <ButtonHTMLExample as={LinkMock}>
            {lorem({ words: 2 })}
          </ButtonHTMLExample>
        </article>
      </Playground>
    </Section>
    <Section title="Huomiolaatikko tilatiedolla">
      <Playground>
        <Message attentionColor="success">
          <MessageHeader
            description={
              <Badge iconLeft={<BulletFillIcon color="success" />}>
                Työnhaku voimassa
              </Badge>
            }
          >
            <Heading
              level={2}
              size={4}
            >
              Työnhakusi on voimassa toistaiseksi
            </Heading>
          </MessageHeader>
          <Paragraph>{lorem({ sentences: 5 })}</Paragraph>
          <Paragraph noMargin>
            <LinkMock>Lorem ipsum</LinkMock>
          </Paragraph>
        </Message>
      </Playground>
      <Playground>
        <Message attentionColor="warning">
          <MessageHeader
            description={
              <Badge iconLeft={<BulletFillIcon color="warning" />}>
                Työnhaku päättymässä
              </Badge>
            }
          >
            <Heading
              level={2}
              size={4}
            >
              Työnhakusi on voimassa 7.4.2019 saakka
            </Heading>
          </MessageHeader>
          <Paragraph>{lorem({ sentences: 5 })}</Paragraph>
          <Paragraph noMargin>
            <LinkMock>Lorem ipsum</LinkMock>
          </Paragraph>
        </Message>
      </Playground>
      <Playground>
        <Message
          attentionColor="danger"
          noMargin
        >
          <MessageHeader
            description={
              <Badge iconLeft={<BulletFillIcon color="danger" />}>
                Työnhaku ei voimassa
              </Badge>
            }
          >
            <Heading
              level={2}
              size={4}
            >
              Työnhakusi ei ole voimassa
            </Heading>
          </MessageHeader>
          <Paragraph>{lorem({ sentences: 5 })}</Paragraph>
          <Paragraph noMargin>
            <LinkMock>Lorem ipsum</LinkMock>
          </Paragraph>
        </Message>
      </Playground>
      <Playground format="html">
        <article className="message message-attention-success">
          <header className="message-header">
            <div className="message-header-content">
              <h2 className="h4">{lorem({ words: 5 })}</h2>
            </div>
            <div className="message-header-description">
              <span className="badge">
                <IconHTMLExample
                  color="success"
                  name="BulletFillIcon"
                  className="badge-icon"
                />
                {lorem({ words: 2 })}
              </span>
            </div>
          </header>
          <p>{lorem({ sentences: 5 })}</p>
          <p className="mb-0">
            <LinkMock>{lorem({ words: 5 })}</LinkMock>
          </p>
        </article>
      </Playground>
      <Playground format="html">
        <article className="message message-attention-warning">
          <header className="message-header">
            <div className="message-header-content">
              <h2 className="h4">{lorem({ words: 5 })}</h2>
            </div>
            <div className="message-header-description">
              <span className="badge">
                <IconHTMLExample
                  color="warning"
                  name="BulletFillIcon"
                  className="badge-icon"
                />
                {lorem({ words: 2 })}
              </span>
            </div>
          </header>
          <p>{lorem({ sentences: 5 })}</p>
          <p className="mb-0">
            <LinkMock>{lorem({ words: 5 })}</LinkMock>
          </p>
        </article>
      </Playground>
      <Playground format="html">
        <article className="message message-attention-danger mb-0">
          <header className="message-header">
            <div className="message-header-content">
              <h2 className="h4">{lorem({ words: 5 })}</h2>
            </div>
            <div className="message-header-description">
              <span className="badge">
                <IconHTMLExample
                  color="danger"
                  name="BulletFillIcon"
                  className="badge-icon"
                />
                {lorem({ words: 2 })}
              </span>
            </div>
          </header>
          <p>{lorem({ sentences: 5 })}</p>
          <p className="mb-0">
            <LinkMock>{lorem({ words: 5 })}</LinkMock>
          </p>
        </article>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentti ei sisällä semantiikkaa. Ruudunlukija lukee sisällön
        tavallisena tekstinä, ei mitenkään korostettuna. Värillä ilmaistu
        merkitys (<Code>success</Code>, <Code>warning</Code>,{' '}
        <Code>danger</Code>) ei välity ruudunlukijalle, joten sama merkitys
        tulee käydä ilmi tekstisisällöstä.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
